<template>
    <div class="box">
        <van-nav-bar :title="$route.meta.title" left-text="" left-arrow @click-left="onClickNavLeft" :fixed="true"
            :placeholder="true" z-index="999" style="background:#05B252;">
            <template #left>
                <img src="@/assets/img/fanhuibai.png" alt="" class="navFanhui imgFit">
            </template>
        </van-nav-bar>
        <div class="bj"></div>

        <div class="outer">
            <div class="name">
                <div>姓名</div>
                <input @input="ckname" v-model="name" type="text" placeholder="请输入">
            </div>
            <div class="name">
                <div>开户行</div>
                <input v-model="kaihu" type="text" placeholder="请输入">
            </div>
            <div class="name">
                <div>银行卡号</div>
                <input @input="ckkh" v-model="kh" type="number" placeholder="请输入">
            </div>
            <div class="name">
                <div>所属银行</div>
                <span @click="xuanze">{{ yhIndex == '-1' ? '请选择' : columns[yhIndex] }}</span>
            </div>
        </div>
        <div class="form" @click="verify">确认</div>
        <van-popup v-model="show" round position="bottom" :style="{ height: '50%' }">
            <van-picker title="选择所属银行" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel" />
        </van-popup>



    </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：添加银行卡
 * @author: 刘义博
 * @version  V1.0 
 * @datecreate: 2024-04-18 9:32
 */
export default {
    data() {
        return {
            name: '',
            kh: '',
            kaihu: '',
            show: false,
            columns: [],
            columnsONe: [],
            yhIndex: '-1',
        };
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.getyinhang()

    },

    methods: {
        /**
          * 卡号不能超过20位
          * 刘义博
          * 2024-4-16
       */
        ckkh() {
            if (this.kh.length > 20) {
                this.kh = this.kh.slice(0, 20);
            }
        },
        /**
          * 姓名不能超过15位
          * 刘义博
          * 2024-4-16
       */
        ckname() {
            if (this.name.length > 15) {
                this.name = this.name.slice(0, 15);
            }
        },
        /**
           * 我的
           * 刘义博
           * 2024-4-16
        */
        getmine() {
            this.$request({
                url: "/Index/my",
                data: {
                    mobile: this.phone,
                },
            }).then((res) => {
                if (res.code == 1) {
                    this.name = res.data.bank.user_name
                    this.kh = res.data.bank.bank_no
                    this.kaihu = res.data.bank.bank_name
                    this.yhIndex = this.findIndexById(res.data.bank.main_bank_name, this.columnsONe)
                }
            }).catch((error) => {
                this.$toast({
                    message: error.msg,
                    duration: 1500,
                });
            });
        },
        /**
          * 匹配获取的银行是哪个
          * 刘义博
          * 2024-4-16
       */
        findIndexById(id, array) {
            for (let i = 0; i < array.length; i++) {
                if (array[i].name === id) {
                    return i; // 返回匹配到的索引
                }
            }
            return -1; // 如果没有找到匹配的ID，则返回-1
        },
        /**
          * 确定
          * 刘义博
          * 2024-4-16
       */
        verify() {
            if (this.name == '') {
                this.$toast({
                    message: '请输入姓名！',
                    duration: 1500
                })
                return
            } else if (this.kaihu == "") {
                this.$toast({
                    message: '请输入开户行！',
                    duration: 1500
                })
                return
            } else if (this.kh == "") {
                this.$toast({
                    message: '请输入银行卡号！',
                    duration: 1500
                })
                return
            } else if (this.yhIndex == '-1') {
                this.$toast({
                    message: '请输入所属银行！',
                    duration: 1500
                })
                return
            }
            this.$request({
                url: "/Index/user_bank_info",
                data: {
                    bank_id: this.columnsONe[this.yhIndex].bank_id,
                    bank_name: this.kaihu,
                    user_name: this.name,
                    bank_no: this.kh

                },
            }).then((res) => {
                this.$toast({
                    message: res.msg,
                    duration: 1500,
                });
                setTimeout(() => {
                    this.$router.go(-1)
                }, 1500)
            }).catch((error) => {
                this.$toast({
                    message: error.msg,
                    duration: 1500,
                });
            });
        },
        /**
          * 获取银行列表
          * 刘义博
          * 2024-4-16
       */
        getyinhang() {
            this.$request({
                url: "/Index/get_bank_list",
                data: {},
            }).then((res) => {
                if (res.code == 1) {
                    this.columns = res.data.map(item => item.name)
                    this.columnsONe = res.data
                    this.getmine()
                }
            }).catch((error) => {
                this.$toast({
                    message: error.msg,
                    duration: 1500,
                });
            });
        },
        /**
          * 弹出选择银行
          * 刘义博
          * 2024-4-16
       */
        xuanze() {
            this.show = true
        },
        /**
          * 提现弹框点击请添加银行卡
          * 刘义博
          * 2024-4-16
       */
        onConfirm(value, index) {
            this.show = false
            this.yhIndex = index
        },
        /**
           * 关闭选择银行
           * 刘义博
           * 2024-4-16
        */
        onCancel() {
            this.show = false
        },
        /**
           * 返回
           * 刘义博
           * 2024-4-16
        */
        onClickNavLeft() {
            this.$router.go(-1)
        },

    },
    destroyed() {

    },
};
</script>
<style lang='scss' scoped>
body {
    background-color: red;
}

:deep(.van-nav-bar__title) {
    color: #ffffff !important;
    margin: 0 auto 0 40px !important;
    font-size: 19px !important;
}

:deep(.van-nav-bar) {
    background: #05B252 !important;
    border: none !important;
    position: fixed !important;
}

:deep(.van-hairline--bottom::after) {
    border: none !important;
}

:deep(.van-nav-bar__content) {
    height: 56px !important;
}

.box {
    background: #F4F4F4 !important;
}

* {
    box-sizing: border-box;
}

.bj {
    width: 100%;
    height: 200px;
    background: #05B252 !important;
    margin-top: -1px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.outer {
    position: relative;
    background: #F4F4F4;
    border-radius: 60px 60px 0px 0px;
    padding: 40px 36px;
    min-height: 300px;

    .name {
        height: 130px;
        background: #FFFFFF;
        border-radius: 24px;
        padding: 30px 36px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        div {
            font-weight: 500;
            font-size: 30px;
            color: #222222;
        }

        input {
            width: 79%;
            text-align: right;
            height: 100%;
            font-size: 30px;
            border: 0;
        }

        span {
            font-weight: bold;
            font-size: 30px;
            color: #222222;
        }
    }
}

.form {
    position: fixed;
    bottom: 50px;
    width: 90%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #05B252;
    font-size: 35px;
    color: #fff;
    border-radius: 50px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
}
</style>